import React from "react";
import { useTimer } from "react-timer-hook";
import { useEffect, useState } from "react";
import abi from "../../ContractData/abi.json";
import usdABI from "../../ContractData/abiUSDT.json";
import "./MainStackComponent.css";
import { toast } from "react-toastify";
import axios from "axios";
import { useTranslation } from "react-i18next";
import {
  useDisconnect,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";
import { ethers, Contract } from "ethers";
import { useNavigate } from "react-router-dom";

function MainStackComponent() {
  const RcpProvidersString = process.env.REACT_APP_PROVIDERS_URL || "[]";
  const RcpProviders = JSON.parse(RcpProvidersString);
  const { disconnect } = useDisconnect();

  const navigate = useNavigate();

  const refreshPage = () => {
    navigate(0);
  };
  const { seconds, minutes, hours, days, restart } = useTimer({
    expiryTimestamp: new Date("2020-02-28"),
    autoStart: true,
  });
  const [logoCoin1, setLogoCoin1] = useState("/assets/img/bnbicon.svg");
  const [logoCoin2, setLogoCoin2] = useState("/assets/img/usdticon.svg");
  const [amountEntered, setAmountEntered] = useState(0);
  const [amountByUSDT, setAmountByUSDT] = useState(0);
  const [progressBar, setProgressBar] = useState({
    totalTokensToSell: 0,
    totalSoledTokens: 0,
  });
  const [minMax, setMinMax] = useState({
    min: 0,
    max: 0,
  });
  const [coinSelected, setCoinSelected] = useState({
    id: 2,
    name: process.env.REACT_APP_SECOND_COIN_NAME,
    address: process.env.REACT_APP_SECOND_COIN_ADDRESS,
    price: 0,
  });
  const [isSHowError, setIsSHowError] = useState(false);
  const [loadingConversions, setLoadingConversions] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingContractConnect, setLoadingContractConnect] = useState(true);
  const [purchaseToken, setPurchaseToken] = useState(0);
  const [walletBalance, setWalletBalance] = useState(0.0);
  const ContractAddres = process.env.REACT_APP_CONTRACT_ADDRESS;
  const ContractAbi = abi.abi;

  const USDTAddress = process.env.REACT_APP_USDT_ADDRESS;
  const USDTAbi = usdABI.abi;
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();

  const _connectProvider = async () => {
    const infuraApiKey = process.env.REACT_APP_INFURA_API_KEY;
    const infuraUrl = `https://mainnet.infura.io/v3/${infuraApiKey}`;

    // const ethersProvider = new ethers.providers.JsonRpcProvider(infuraUrl); // Mainnet Ethereum Chain

    // const ethersProvider = new ethers.providers.JsonRpcProvider(     // Binance Chain
    // RcpProviders[1]
    // await ethersProvider.ready;
    // );
    // return ethersProvider;

    const ethersProvider = new ethers.providers.Web3Provider(walletProvider);
    await ethersProvider.ready;
    const signer = ethersProvider.getSigner();

    return signer;
  };
  const callMethods = async () => {
    if (isConnected) {
      try {
        setLoadingContractConnect(true);
        const ethersProvider = await _connectProvider();

        const USDTContract = new Contract(
          ContractAddres,
          ContractAbi,
          ethersProvider
        );
        console.log(USDTContract);
        const minimumRes = await USDTContract.minTokensToPurchase();
        console.log(minimumRes, "minimumRes");
        const maximumRes = await USDTContract.maxTokensToPurchase();
        console.log(maximumRes, "maximumRes");
        // console.log(
        const min = ethers.utils.formatUnits(minimumRes._hex);
        const max = ethers.utils.formatUnits(maximumRes._hex);
        // );
        if (maximumRes && minimumRes) {
          setMinMax({
            min: min,
            max: max,
          });
        }

        const res1 = await USDTContract.expirationTimestamp();
        console.log(res1, "expirationTimestamp");
        const time = parseInt(res1);
        if (new Date(time)) {
          restart(new Date(time * 1000), true);
        }

        const totalTokensToSell = await USDTContract.totalTokensToSell();
        console.log(totalTokensToSell, "totalTokensToSell");
        const totalSoledTokens = await USDTContract.totalSoledTokens();
        console.log(totalSoledTokens, "totalSoledTokens");
        if (totalSoledTokens && totalSoledTokens) {
          const sell = ethers.utils.formatUnits(totalTokensToSell._hex);
          const soled = ethers.utils.formatUnits(totalSoledTokens._hex);
          setProgressBar({
            totalTokensToSell: sell,
            totalSoledTokens: soled,
          });
        }

        const tokensConversions = await _getTokensConversions();
        console.log(tokensConversions, "_getTokensConversions");

        setLoadingContractConnect(false);
      } catch (e) {
        console.log(e);
        setLoadingContractConnect(false);
      }
    } else {
      toast.info("please connect wallet first");
    }
  };

  const _getPurchased = async () => {
    if (isConnected) {
      console.log("_getPurchased");
      const ethersProvider = new ethers.providers.Web3Provider(walletProvider);
      const signer = await ethersProvider.getSigner();
      console.log(ContractAddres, ContractAbi, signer, "_getPurchased");
      const USDTContract = new Contract(ContractAddres, ContractAbi, signer);
      const yourPurchasedTokens = await USDTContract.yourPurchasedTokens();
      console.log(yourPurchasedTokens, "yourPurchasedTokens");
      if (yourPurchasedTokens) {
        const purchasedTokens = ethers.utils.formatUnits(
          yourPurchasedTokens._hex
        );
        const res = purchasedTokens * 1000000000000000000;
        console.log(purchasedTokens, "purchasedTokens");
        setPurchaseToken(res);
      }
    }
  };

  const _getTokensConversions = async () => {
    if (isConnected) {
      const ethersProvider = await _connectProvider();
      try {
        setLoadingConversions(true);
        const USDTContract = new Contract(
          ContractAddres,
          ContractAbi,
          ethersProvider
        );
        let PriceRes;
        if (coinSelected?.id === 1) {
          PriceRes = await USDTContract.bnbPrice();
        } else if (coinSelected?.id === 2) {
          PriceRes = await USDTContract.usdtPrice();
        }
        if (PriceRes) {
          const Price = PriceRes.toNumber();
          setCoinSelected((prev) => ({
            ...prev,
            price: Price,
          }));
          setLoadingConversions(false);
        }
      } catch (e) {
        setLoadingConversions(false);
      }
    } else {
      toast.info("please connect wallet first");
    }
  };
  const [check, setCheeck] = useState(false);
  useEffect(() => {
    setAmountByUSDT(amountEntered * coinSelected?.price);
  }, [amountEntered, coinSelected]);
  useEffect(() => {
    console.log(isConnected, "1");
    if (isConnected && coinSelected?.id) {
      console.log(isConnected, "2");
      _getTokensConversions();
    } else {
      const aa = setTimeout(() => {
        console.log(isConnected, "3");
        console.log(isConnected, "4");
        setCheeck(true);
      }, 5000);
    }
  }, [coinSelected?.id, isConnected]);

  useEffect(() => {
    if (check && !isConnected) {
      toast.info(`Please Connect Wallet To Show Real Details`, {
        position: "top-center",
        autoClose: false,
        toastId: "pcw",
      });
    } else {
      toast.dismiss("pcw");
    }
  }, [check, isConnected]);

  const handleChangeAmount = async (value) => {
    setAmountEntered(value);
    // setAmountByUSDT(value.toString());

    if (value && coinSelected?.price !== 0) {
      setAmountEntered(value);
      const a = value * coinSelected?.price;
      setAmountByUSDT(value * coinSelected?.price);
      if (a > minMax.max || a < minMax.min) {
        setIsSHowError(true);
      } else {
        setIsSHowError(false);
      }
    } else {
      // setAmountEntered(0);
      // setAmountByUSDT(0);
    }
  };

  const getBalance = async () => {
    if (isConnected) {
      const ethersProvider = new ethers.providers.Web3Provider(walletProvider);
      const USDTBalance = await ethersProvider.getBalance(address);
      setWalletBalance(ethers.utils.formatUnits(USDTBalance, 18));
    }
  };

  const _buyTokens = async () => {
    if (isConnected) {
      const ethersProvider = new ethers.providers.Web3Provider(walletProvider);
      const signer = ethersProvider.getSigner();
      const USDTContract = new Contract(ContractAddres, ContractAbi, signer);
      try {
        setLoading(true);

        const ress = ethers.utils.parseUnits(amountEntered, 18);
        console.log(parseFloat(amountEntered), amountEntered, ress);
        let res;
        let transaction;
        if (coinSelected?.id === 1) {
          transaction = await USDTContract.buyUsingBnb({ value: ress });
          res = await transaction.wait();
        } else {
          const USDContract = new Contract(USDTAddress, USDTAbi, signer);
          const approveRes = await USDContract.approve(ContractAddres, ress);
          console.log(approveRes, "approve res");
          const approve = await approveRes.wait();
          if (approve.status === 1) {
            transaction = await USDTContract.buyUsingUsdt(ress);
            res = await transaction.wait();

            if (res?.status === 1) {
              // console.log(walletProvider, "walletProvider");
              getBalance();
              callMethods();
              toast.success(`Transaction Confirmed`);
              setLoading(false);
              // walletProvider.on("");
              // walletProvider.on("disconnect", () => {});
              // walletProvider.on("connect", () => {});
              refreshPage();
              // setAmountEntered(0);
              // setAmountByUSDT(0);
            }
          } else {
            toast.error(`You don't have enough balance!`);
          }
        }
      } catch (e) {
        getBalance();
        setLoading(false);
        if (e.data) {
          toast.error(
            `You don't have enough balance! Your balance is ${walletBalance} ${coinSelected?.name}`
          );
        } else {
          setLoading(false);
          console.log(e);
          toast.error(e.message.split("(")[0]);
        }
      }
    }
  };

  const _checkNetwork = () => {
    return parseInt(chainId) == process.env.REACT_APP_NETWORK_CHAIN_ID;
  };

  const _checkIsCorrectNetwork = async () => {
    if (!_checkNetwork()) {
      try {
        setLoading(true);
        toast.info("You Have Pending Request, Please Check Your Wallet", {
          autoClose: false,
          toastId: "pending",
        });
        const res1 = await walletProvider.request({
          method: "wallet_switchEthereumChain",
          params: [
            {
              chainId: ethers.utils.hexlify(
                parseFloat(process.env.REACT_APP_NETWORK_CHAIN_ID)
              ),
            },
          ],
        });
        console.log(res1, "res1");
        // if (res1 && !res1.error) {
        // Handle success
        toast.dismiss("pending");
        setLoading(false);
        // }
        // await _addCustomToken();
      } catch (err) {
        console.error("errrrrrrrrrrrrrrrrrrrrrrrrrrr", err);
        toast.dismiss("pending");
        setLoading(false);
        if (err.code === 4902) {
          try {
            setLoading(true);
            const res2 = await walletProvider.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: ethers.utils.hexlify(
                    parseFloat(process.env.REACT_APP_NETWORK_CHAIN_ID)
                  ),
                  nativeCurrency: {
                    name: process.env.REACT_APP_NETWORK_CURRENCY,
                    symbol: process.env.REACT_APP_NETWORK_CURRENCY,
                    decimals: 18,
                  },
                  // iconUrls: [
                  //   "https://xdaichain.com/fake/example/url/xdai.svg",
                  //   "https://xdaichain.com/fake/example/url/xdai.png",
                  // ],
                  chainName: process.env.REACT_APP_NETWORK_NAME,
                  blockExplorerUrls: [
                    process.env.REACT_APP_NETWORK_EXPLORE_URL,
                  ],
                  rpcUrls: [process.env.REACT_APP_NETWORK_RCP_URL],
                },
              ],
            });
            // if (res2) {
            setLoading(false);
            // }
            // await _addCustomToken();
          } catch (addError) {
            setLoading(false);
            // await handleDisconnect();
            console.log(addError);
            // toast.info(
            //   "Operation failed. Choose the Binance Smart Chain on your wallet"
            // );
            return;
          }
        } else {
          setLoading(false);
          // toast.info(
          //   "Operation failed. Choose the Binance Smart Chain on your wallet"
          // );
          if (isConnected) {
            disconnect();
          }
          toast.info(
            `Please Switch To Network ${process.env.REACT_APP_NETWORK_NAME}`
          );
        }
      }
    } else {
      setLoading(false);
    }
    setLoading(false);
  };

  const setmin = async () => {
    // setMinTokensToPurchase;
    const ethersProvider = new ethers.providers.Web3Provider(walletProvider);
    const signer = ethersProvider.getSigner();
    const USDTContract = new Contract(ContractAddres, ContractAbi, signer);
    const ress = ethers.utils.parseUnits(amountEntered, 18);
    console.log(parseFloat(amountEntered), amountEntered, ress);
    let res;
    let transaction;
    transaction = await USDTContract.setMinTokensToPurchase(
      ethers.utils.parseUnits("0.00001", 18),
      {
        value: "0.00001",
      }
    );
    console.log(transaction, "setMinTokensToPurchase");
  };

  const _addCustomToken = async () => {
    const tokenAddress = process.env.REACT_APP_FIRST_TOKEN_ADDRESS;
    const tokenSymbol = process.env.REACT_APP_FIRST_TOKEN_SYMBOL;
    const tokenName = process.env.REACT_APP_FIRST_TOKEN_NAME;
    const tokenDecimals = process.env.REACT_APP_FIRST_TOKEN_DECIMALS;
    try {
      const wasAdded = await walletProvider.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: tokenAddress,
            symbol: tokenSymbol,
            name: tokenName,
            decimals: tokenDecimals,
            // image: tokenImage,
          },
        },
      });

      if (wasAdded) {
        toast.success(`Thanks for your interest! with our Token ${tokenName}`);
      } else {
        console.log("Your loss!");
      }
    } catch (error) {
      toast.error(`error when adding our token: ${error?.message}`);
      console.log(error);
    }
  };

  const _afterConnect = async () => {
    console.log("44444444444444444444");
    if (_checkNetwork()) {
      console.log("_afterConnect");
      const ethersProvider = new ethers.providers.Web3Provider(walletProvider);
      await ethersProvider.ready;
      const signer = ethersProvider.getSigner();
      if (signer) {
        console.log("signer");
        callMethods();
        getBalance();
        _getPurchased();
      }
    }
  };
  useEffect(() => {
    if (isConnected) {
      _afterConnect();
    }
  }, [isConnected, chainId]);
  useEffect(() => {
    // callMethods();
  }, []);

  const _getIcons = async (index, address) => {
    const options = {
      method: "GET",
      url: `https://cryptofonts-token-icon-api1.p.rapidapi.com/${address}`,
      headers: {
        "X-RapidAPI-Key": `${process.env.REACT_APP_KEY_ICONS_SERVER}`,
        "X-RapidAPI-Host": "cryptofonts-token-icon-api1.p.rapidapi.com",
      },
    };

    try {
      const response = await axios.request(options);

      if (response?.data?.length > 0) {
        if (index === 1) {
          setLogoCoin1(response?.data[0]?.logoURI);
        }
        if (index === 2) {
          setLogoCoin2(response?.data[0]?.logoURI);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    _getIcons(1, process.env.REACT_APP_FIRST_COIN_ADDRESS);
    _getIcons(2, process.env.REACT_APP_SECOND_COIN_ADDRESS);
  }, []);
  const handleChangeCoin = (num) => {
    if (num === 1) {
      setCoinSelected({
        id: 1,
        name: process.env.REACT_APP_FIRST_COIN_NAME,
        address: process.env.REACT_APP_FIRST_COIN_ADDRESS,
      });
    }
    if (num === 2) {
      setCoinSelected({
        id: 2,
        name: process.env.REACT_APP_SECOND_COIN_NAME,
        address: process.env.REACT_APP_SECOND_COIN_ADDRESS,
      });
    }
  };

  useEffect(() => {
    if (isConnected && chainId && !_checkNetwork()) {
      _checkIsCorrectNetwork();
    }
  }, [isConnected, chainId]);
  const [t] = useTranslation();
  return (
    <div
      className="gap-1 pt-10 p-0 md:p-24 flex justify-between items-center flex-col-reverse md:flex-row"
      id=""
    >
      <div
        className="w-full description-widget md:w-[500px]"
        style={{ textAlign: "center" }}
      >
        <div className="mb-56">
          <span>{t("smart2")} </span>
        </div>
        <p className="pt-28">{t("smart3")}</p>
        <div className="pt-28 flex items-center flex-nowrap justify-between">
          <button
            className="p-2 text-[10px] w-32"
            style={{
              backgroundColor: "#47a1ff",
              color: "#000000",
              borderRadius: "15px",
              marginLeft: "10px",
            }}
          >
            <a
              rel="noreferrer"
              href="https://drive.google.com/file/d/1bfSDsY4D-BIKVsYaYorrOiNX9cu49Ag9/view?usp=drive_link"
              className=" w-full text-dark font-weight-bold"
              target="_blank"
            >
              {t("smart4")}
            </a>
          </button>
          <button
            className="p-2 text-[10px] w-25"
            style={{
              backgroundColor: "#47a1ff",
              color: "#000000",
              borderRadius: "15px",
            }}
          >
            <a
              rel="noreferrer"
              href="https://app.solidproof.io/projects/wonder-energy-technologywte"
              className=" w-full text-dark font-weight-bold"
              target="_blank"
            >
              {t("smart5")}
            </a>
          </button>
          <button
            className="p-2 text-[10px] w-25"
            style={{
              backgroundColor: "#47a1ff",
              color: "#000000",
              borderRadius: "15px",
            }}
          >
            <a
              rel="noreferrer"
              href="https://github.com/solidproof/projects/tree/main/2024/WONDER%20ENERGY%20TECHNOLOGY%20(WTE)"
              className="  w-full text-dark font-weight-bold"
              target="_blank"
            >
              {t("smart6")}
            </a>
          </button>
        </div>
      </div>
      <div className="counter-widget w-full md:w-[500px]">
        <div className=" !w-full">
          <div className="counter-top text-center p-3 d-flex justify-content-start flex-column">
            <div className="counter-element">
              <div className="counter-item-div rounded-3">
                <span>{loadingContractConnect ? "--" : days}</span>
                <span>{t("time1")}</span>
              </div>
              <div className="counter-item-div rounded-3">
                <span>{loadingContractConnect ? "--" : hours}</span>

                <span>{t("time2")}</span>
              </div>
              <div className="counter-item-div rounded-3">
                <span>{loadingContractConnect ? "--" : minutes}</span>
                <span>{t("time3")}</span>
              </div>
              <div className="counter-item-div rounded-3">
                <span>{loadingContractConnect ? "--" : seconds}</span>
                <span>{t("time4")}</span>
              </div>
            </div>
            <div className="counter-banner-text">{t("smart7")}</div>
            <div className="counter-element-progress">
              <div
                className={`counter-element-progress-in ${
                  loadingContractConnect ? "progressAnimation" : ""
                }`}
                style={{
                  width: `${
                    loadingContractConnect
                      ? "100%"
                      : (progressBar.totalSoledTokens ===
                          progressBar.totalTokensToSell) ===
                        0
                      ? 0
                      : (progressBar.totalSoledTokens * 100) /
                        progressBar.totalTokensToSell
                  }%`,
                }}
              />
              <div className="counter-text-progress">{t("smart12")}</div>
            </div>
            <div className="counter-desc-row mt-3">
              WTE Raised: {progressBar.totalSoledTokens} WTE /
              {progressBar.totalTokensToSell} WTE
            </div>
            {isConnected && (
              <div className="counter-desc-row bolded">
                <span>
                  {t("smart13")} ={" "}
                  {purchaseToken && !isNaN(purchaseToken / 1000000000000000000)
                    ? purchaseToken / 1000000000000000000
                    : 0}{" "}
                </span>{" "}
                <img
                  alt="stack-icon"
                  className="ms-2 cursor-pointer img-fluid"
                  src="/assets/img/info.svg"
                />
              </div>
            )}

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              {!isConnected ? (
                <w3m-connect-button
                  balance={"hide"}
                  size="sm"
                  loadingLabel="Connecting"
                />
              ) : (
                <>
                  <w3m-network-button />
                  <w3m-button balance={"hide"} size="sm" />
                </>
              )}
            </div>
          </div>
          {/* <button onClick={setmin}>min</button> */}
          <div className="counter-footer">
            <div
              className={`${
                coinSelected?.price == 0 && !loadingContractConnect
                  ? "hidden"
                  : ""
              } card-footer-title`}
            >
              1 WTE ={" "}
              {loadingContractConnect ||
              loadingConversions ||
              isNaN(1 / coinSelected?.price)
                ? "..."
                : 1 / coinSelected?.price}
              {"  "}
              {coinSelected?.name}
            </div>
            <div className="card-options-container text-center w-full flex items-center justify-center">
              <div
                onClick={() => handleChangeCoin(1)}
                className={`card-option-element btn btn-light font-14 text-uppercase d-flex align-items-center justify-content-center  ${
                  coinSelected.id === 1 ? "selected bg-[#c7ccd1]" : ""
                }`}
              >
                <img
                  className="relative h-8"
                  alt="stack-icon"
                  height={"8"}
                  src={logoCoin1}
                />
                <span className="px-2 text-[10px] md:text-[12px] lg:text-[14px]">
                  {process.env.REACT_APP_FIRST_COIN_NAME}
                </span>
              </div>
              <div
                onClick={() => handleChangeCoin(2)}
                className={` relative card-option-element btn btn-light font-14 text-uppercase d-flex align-items-center justify-content-center ${
                  coinSelected.id === 2 ? "selected bg-[#c7ccd1]" : ""
                }`}
              >
                <img
                  className="relative h-8"
                  alt="stack-icon"
                  height={"28"}
                  src={logoCoin2}
                />
                <span className="px-2 text-[10px] md:text-[12px] lg:text-[14px]">
                  {process.env.REACT_APP_SECOND_COIN_NAME}
                </span>
              </div>
            </div>
            <div className="card-options-container mb-2 mt-3">
              <div className="card-form-field col-md-6">
                <div className="field-top-label">
                  <span className="d-block family-title font-12 fw-normal text-truncate text-black">
                    {t("smart10")} {coinSelected?.name} {t("smart11")}
                  </span>
                </div>
                <div className="card-input-container">
                  <input
                    className={` ${
                      isSHowError ? "!border !border-red-500" : ""
                    }`}
                    value={amountEntered}
                    placeholder="0"
                    disabled={loading || loadingConversions}
                    // placeholder="0.1"
                    onChange={(e) => handleChangeAmount(e.target.value)}
                    type="number"
                  />
                  <img
                    className="h-8 rounded-full w-8 object-cover"
                    alt="stack-icon"
                    src={
                      coinSelected?.id === 1 ? `${logoCoin1}` : `${logoCoin2}`
                    }
                    height={28}
                  />
                </div>
              </div>
              <div className="card-form-field col-md-6">
                <div className="field-top-label">
                  <span className="d-block family-title font-12 fw-normal text-truncate text-black">
                    {t("smart9")}
                  </span>
                </div>
                <div className="card-input-container">
                  <input
                    placeholder="0"
                    disabled
                    value={amountByUSDT}
                    type="number"
                  />
                  <img
                    className="h-8 rounded-full w-8 object-cover"
                    alt="stack-icon"
                    src="/assets/img/logodark.jpg"
                    height={28}
                  />
                </div>
              </div>
            </div>
            {isSHowError && (
              <small className="text-red-500 w-full block text-center">
                Please Set Value Between {minMax.min}-{minMax.max} WTE
              </small>
            )}
            <div className="card-button-container d-flex flex-column align-items-center justify-content-center ng-star-inserted">
              {/* <button
                // disabled={loading}
                // onClick={addressWallet ? disconnectWallet : connectWallet}
                style={{ color: "#000" }}
                className={`btn ${
                  addressWallet ? "btn-danger" : "btn-blue"
                }  mb-2 fs-7 w-100 fw-bold`}
              >
                {loading
                  ? "loading"
                  : addressWallet
                  ? "Disconnect Wallet"
                  : "Connect Wallet"}
              </button> */}

              <button
                disabled={isSHowError || loading}
                onClick={
                  !isConnected
                    ? () => toast.info("Please Connect To Your Wallet First")
                    : amountEntered
                    ? _buyTokens
                    : () => toast.info("Please Enter Amount First")
                }
                style={{ backgroundColor: "#5ce1e6", color: "#000" }}
                className={`btn !bg-[#5ce1e6] h-[67px] btn-success fs-7 w-100 fw-bold`}
              >
                {loading ? (
                  <div className="flex h-full items-center justify-center gap-x-5">
                    <svg
                      className=" w-6 h-6 text-[#00c4f4] animate-spin fill-black"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    loading...
                  </div>
                ) : (
                  `Buy WTE By ${coinSelected?.name}`
                )}
              </button>
            </div>
            <div className="mt-2 ">
              <div
                className="font-12 poweredBy flex items-center justify-center text-center d-flex"
                style={{ justifyContent: "center", alignContent: "center" }}
              >
                <p className="pt-[15px]">Powered By</p>
                <a
                  rel="noreferrer"
                  href="https://ramaaz.com"
                  className="pl-1 !hover:underline"
                  target="_blank"
                >
                  <img
                    className="h-8"
                    alt="stack-icon"
                    height={"20"}
                    src="/assets/ramaaz.svg"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainStackComponent;
