import React from "react";
import "./header.css";
import { useTranslation } from "react-i18next";
const Header = () => {
  const [t] = useTranslation();
  return (
    <div className="hero_area" id="home">
      <div className="hero_bg_box">
        <div className="bg_img_box">
          <img src="images/banner_bg.png" alt="" />
        </div>
      </div>

      <section className="slider_section ">
        <div
          id="customCarousel1"
          className="carousel slide"
          data-ride="carousel"
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <div className="container ">
                <div className="row">
                  <div className="col-md-6 fade-in-left">
                    <div className="detail-box" style={{ paddingTop: "100px" }}>
                      <h1 className="text-light">{t("title")}</h1>

                      <div className="btn-box">
                        <a href="/private-sale" className="btn1">
                          {t("butt")}
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="img-box">
                      <img src="images/slider-img.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Header;
