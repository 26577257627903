import React from "react";
import { useTranslation } from "react-i18next";
function Header() {
  const [t, i18n] = useTranslation();
  return (
    <div className="relative stack-site-header flex items-center justify-between header desktop">
      <div
        style={{ maxWidth: 100, maxHeight: 100 }}
        className=" max-w-18 max-h-18 w-18 h-18"
      >
        <a className="max-w-18 max-h-18" href="/">
          <img
            className="max-w-18 max-h-18"
            src="/assets/img/logo/logo.png"
            alt=""
          />
        </a>
      </div>

      <div>
        <button
          className="btn-box"
          style={{
            backgroundColor: "#00C4F4",
            marginRight: "10px",
            width: "75px",
            padding: "15px",
            borderRadius: "10px",
          }}
        >
          <a href="/" className="btn1">
            home
          </a>
        </button>
        <button
          className="btn-box"
          style={{
            backgroundColor: "#00C4F4",
            cursor: "pointer",
            width: "75px",
            padding: "15px",
            borderRadius: "10px",
          }}
        >
          {i18n.language == "ar" && (
            <option
              className="btn1"
              onClick={() => {
                i18n.changeLanguage("en");
              }}
            >
              English
            </option>
          )}
          {i18n.language == "en" && (
            <option
              className="btn1"
              onClick={() => {
                i18n.changeLanguage("ar");
              }}
            >
              Arabic
            </option>
          )}
        </button>
      </div>
    </div>
  );
}

export default Header;
