import React from 'react'
import { useTranslation } from "react-i18next";
const Contact = () => {
	const [t] = useTranslation();
  return (
    <section class="ftco-section" id='contact'>
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-md-6 text-center mb-5">
					<h2 class="heading-section">{t('contact1')}</h2>
				</div>
			</div>
			<div class="row justify-content-center">
				<div class="col-lg-10">
					<div class="wrapper">
						<div class="row no-gutters">
							<div class="col-md-6 d-flex align-items-stretch">
								<div class="contact-wrap w-100 p-md-5 p-4 py-5">
									<h3 class="mb-4">{t('contact2')}</h3>
									<div id="form-message-warning" class="mb-4"></div> 
				      		<div id="form-message-success" class="mb-4">
				            Your message was sent, thank you!
				      		</div>
									<form method="POST" id="contactForm" name="contactForm" class="contactForm">
										<div class="row">
											<div class="col-md-12">
												<div class="form-group"><p className='text-light'>{t('contact3')}</p>
													<input type="text" class="form-control text-dark" name="name" id="name" placeholder="Name "/>
												</div>
											</div>
											<div class="col-md-12"> 
												<div class="form-group"><p className='text-light'>{t('contact4')}</p>
													<input type="email" class="form-control text-dark" name="email" id="email" placeholder="Email"/>
												</div>
											</div>
											<div class="col-md-12">
												<div class="form-group"><p className='text-light'>{t('contact5')}</p>
													<textarea name="message" class="form-control text-dark" id="message" cols="30" rows="6" placeholder="Message"></textarea>
												</div>
											</div>
											<div class="col-md-12">
												<div class="form-group">
													<input type="submit" value={t('contact6')} class="btn btn-primary"/>
													<div class="submitting"></div>
												</div>
											</div>
										</div>
									</form>
								</div>
							</div>
							<div class="col-md-6 d-flex align-items-stretch">
								<div class="info-wrap w-100 p-md-5 p-4 py-5 img" >
									<h3 style={{paddingBottom:"15px" , textAlign:"center"}}>{t('contact7')}</h3>
									
				        	<div class="dbox w-100 d-flex align-items-start">
				        		<div class="icon d-flex align-items-center justify-content-center">
				        			<span class="fa fa-map-marker"></span>
				        		</div>
				        		<div class="text pl-3">
					            <p className='text-light'><span>Address:</span> Office No. 43-44 Owned by Dubai Municipality Bur Dubai- Al Fahidi</p>
					          </div>
				          </div>
				        	
				        	<div class="dbox w-100 d-flex align-items-center">
				        		<div class="icon d-flex align-items-center justify-content-center">
				        			<span class="fa fa-paper-plane"></span>
				        		</div>
				        		<div class="text pl-3">
					            <p className='text-light'><span>Email:</span> <a href="mailto:info@yoursite.com" className='text-light'>admin@wtenergy.tech</a></p>
					          </div>
				          </div>
				        	
			          </div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
  )
}

export default Contact
