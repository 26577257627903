
import { Nav ,  Header , CountdownTime , Roadmap , About , Choose, Container ,Contact , Footer , Faq } from "../components";

function Home() {
  return (
    <>
      <Nav/>
      <Header/>
      <Container>
      <CountdownTime/>
      <About/><br/><br/>
      <Choose/><br/><br/>
      <Roadmap/><br/><br/>
      <Faq/><br/><br/> 
      <Contact/>
      </Container>
      <Footer/>
      
    </>
  );
}

export default Home;