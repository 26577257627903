import React, { useState } from 'react';
import './Faq.css';
import { useTranslation } from "react-i18next";
const Faq = () => {
  const [t] = useTranslation();
	const [isOpen, setIsOpen] = useState(Array(2).fill(false)); // Adjust the array size based on the number of questions

	const toggleFAQ = (index) => {
	  const newIsOpen = isOpen.map((item, i) => (i === index ? !item : false));
	  setIsOpen(newIsOpen);
	};

  return (
    <div className="faq-section" id='faq'>
      <h2 className="h1 mb-3" style={{textAlign:"center"}}>{t('faq1')}</h2>
      <div className={`faq ${isOpen[0] ? 'open' : ''}`} onClick={() => toggleFAQ(0)}>
        <div className="question" style={{textAlign:"center"}}>{t('faq2')}</div>
        <div className="answer" style={{textAlign:"center"}}><p>{t('faq3')}
                            <br/>
                            {t('faq4')}
                            <br/>
                            {t('faq5')}
                            <br/>
                            {t('faq6')}
                            <br/>
                            {t('faq7')}
                            </p></div>
      </div>
      <div className={`faq ${isOpen[1] ? 'open' : ''}`} onClick={() => toggleFAQ(1)}>
        <div className="question" style={{textAlign:"center"}}>{t('faq8')}</div>
        <div className="answer" style={{textAlign:"center"}}><p>{t('faq9')}
                        <br/>
                        {t('faq10')}
                        <br/>
                        {t('faq11')}
                        <br/>
                        {t('faq12')}
                        <br/>
                        {t('faq13')}
                        </p></div>
      </div>
    </div>
	
  );
};

export default Faq;
