import React from 'react';
import './Choose.css';
// import './Choose.scss';
import './choos.css'
import { useTranslation } from "react-i18next";
const Choose = () => {
  const [t] = useTranslation();
  return (
    <div className="feat bg-gray pt-5 pb-5" id="why">
      <div className="container">
        <div className="row">
          <div className="section-head col-sm-12">
            <h4>
              <span>{t('choose1')}</span>
            </h4>
            <p>{t('choose2')}</p>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="item fade-in">
              <span className="icon feature_box_col_one">
                <i className="fa-solid fa-money-check-dollar"></i>
              </span>
              <h6>{t('choose3')}</h6>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="item fade-in">
              <span className="icon feature_box_col_two">
                <i className="fa-solid fa-hand-holding-dollar"></i>
              </span>
              <h6>{t('choose4')}</h6>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="item fade-in">
              <span className="icon feature_box_col_three">
                <i className="fa-solid fa-money-bill-trend-up"></i>
              </span>
              <h6>{t('choose5')}</h6>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="item fade-in">
              <span className="icon feature_box_col_four">
                <i className="fa-solid fa-lock"></i>
              </span>
              <h6>{t('choose6')}</h6>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="item fade-in">
              <span className="icon feature_box_col_five">
                <i className="fa-solid fa-dumpster"></i>
              </span>
              <h6>{t('choose7')}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Choose;
