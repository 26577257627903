import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";

import ErrorPage from "./ErrorPage.jsx";
import StackWTE from "./Pages/StackWTE.js";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, Slide } from "react-toastify";
// import { createWeb3Modal, defaultConfig } from "@web3modal/ethers5/react";
import "./App.css";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers5/react";
// 1. Get projectId at https://cloud.walletconnect.com

const projectId = process.env.REACT_APP_PROJECT_ID;
if (!projectId) {
  throw new Error("VITE_PROJECT_ID is not set");
}
// 2. Set chains
const metadata = {
  name: "Web3Modal",
  description: "Web3Modal Example",
  url: "https://web3modal.com",
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

const customChain = {
  chainId: parseInt(process.env.REACT_APP_NETWORK_CHAIN_ID),
  name: process.env.REACT_APP_NETWORK_NAME,
  currency: process.env.REACT_APP_NETWORK_CURRENCY,
  explorerUrl: process.env.REACT_APP_NETWORK_EXPLORE_URL,
  rpcUrl: process.env.REACT_APP_NETWORK_RCP_URL,
};
const chains = [
  // mainnet,
  customChain,
  {
    chainId: 1,
    name: "Ethereum",
    currency: "ETH",
    explorerUrl: "https://etherscan.io",
    rpcUrl: "https://cloudflare-eth.com",
  },
];

// 3. Create modal
const ethersConfig = defaultConfig({
  metadata: {
    name: "Web3Modal",
    description: "Web3Modal Laboratory",
    url: "https://wte-private-sale.vercel.app",
    icons: ["https://avatars.githubusercontent.com/u/37784886"],
  },
  defaultChainId: parseInt(process.env.REACT_APP_NETWORK_CHAIN_ID),
  rpcUrl: "https://cloudflare-eth.com",
  enableCoinbase: false,
});

createWeb3Modal({
  projectId,
  allWallets: "HIDE",
  ethersConfig,
  chains: chains,
  featuredWalletIds: [
    "4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0", // trust wallet
    "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96", // meta mask
  ],
  includeWalletIds: [
    "4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0", // trust wallet
    "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96", // meta mask
  ],
  //...
  enableAnalytics: true,
  themeMode: "dark",
  themeVariables: {
    "--w3m-color-mix": "#fafafafa",
    "--w3m-accent": "#5ce1e6",
    "--w3m-font-size-master": "9px",
    "--w3m-border-radius-master": "5px",
    "--w3m-accent	": "#404040",
    "--w3m-accent-color": "#5ce1e6",
    "--w3m-accent-fill-color": "#fafafafa",
    "--w3m-background-color": "#5ce1e6",
  },
});

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />} />
          <Route path="/private-sale" element={<StackWTE />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer position="bottom-left" transition={Slide} />
    </>
  );
}

export default App;
