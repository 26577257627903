
import { useTranslation } from "react-i18next";
import './Roadmap.css'
const Roadmap = () => {
    const [t] = useTranslation();
  return (
    <div class="demo" id='Roadmap'>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="main-timeline" >
                    <div class="timeline" style={{padding:"5rem" , textAlign:"center"}}>
                        <span class="timeline-icon">Q1</span>
                        <div class="timeline-content">
                            
                            <p class="description">
                            {t('roadmap1')}<br/>
                            {t('roadmap2')}<br/>
                            {t('roadmap3')}<br/>
                            {t('roadmap4')}<br/>
                            {t('roadmap5')}
                            </p>
                        </div>
                        <div class="year"><span>Q1 2024</span></div>
                    </div>
                    <div class="timeline" style={{padding:"5rem" , textAlign:"center"}}>
                        <span class="timeline-icon">Q2</span>
                        <div class="timeline-content">
                            
                            <p class="description">
                            {t('roadmap6')}<br/>
                            {t('roadmap7')}<br/>
                            {t('roadmap8')}
                            </p>
                        </div>
                        <div class="year"><span>Q2 2024</span></div>
                    </div>
					<div class="timeline" style={{padding:"5rem" , textAlign:"center"}}>
                        <span class="timeline-icon">Q3</span>
                        <div class="timeline-content">
                            
                            <p class="description">
                            {t('roadmap9')}<br/>
                            {t('roadmap10')}
                            </p>
                        </div>
                        <div class="year"><span>Q3 2024</span></div>
                    </div>
					<div class="timeline" style={{padding:"5rem" , textAlign:"center"}}>
                        <span class="timeline-icon">Q4</span>
                        <div class="timeline-content">
                            
                            <p class="description">
                            {t('roadmap11')}<br/>
                            {t('roadmap12')}<br/>
                            {t('roadmap13')}
                            </p>
                        </div>
                        <div class="year"><span>Q4 2024</span></div>
                    </div>
					<div class="timeline" style={{padding:"5rem" , textAlign:"center"}}>
                        <span class="timeline-icon">Q1</span>
                        <div class="timeline-content">
                            
                            <p class="description">
                            {t('roadmap14')}<br/>
                            {t('roadmap15')}
                            </p>
                        </div>
                        <div class="year"><span>Q1 2025</span></div>
                    </div>
					<div class="timeline" style={{padding:"5rem" , textAlign:"center"}}>
                        <span class="timeline-icon">Q2</span>
                        <div class="timeline-content">
                            
                            <p class="description">
                            {t('roadmap16')}<br/>
                            {t('roadmap17')}
                            </p>
                        </div>
                        <div class="year"><span>Q2 2025</span></div>
                    </div>
					<div class="timeline" style={{padding:"5rem" , textAlign:"center"}}>
                        <span class="timeline-icon">Q3</span>
                        <div class="timeline-content">
                            
                            <p class="description">
                            {t('roadmap18')}<br/>
                            {t('roadmap19')}<br/>
                            {t('roadmap20')}<br/>
                            {t('roadmap21')}
                            </p>
                        </div>
                        <div class="year"><span>Q3 2025</span></div>
                    </div>
                    <div class="timeline" style={{padding:"5rem" , textAlign:"center"}}>
                        <span class="timeline-icon">Q4</span>
                        <div class="timeline-content">
                            
                            <p class="description">
                            {t('roadmap22')}<br/>
                            {t('roadmap23')}<br/>
                            {t('roadmap24')}<br/>
                            {t('roadmap25')}
                            </p>
                        </div>
                        <div class="year"><span>Q4 2025</span></div>
                    </div>
					
                </div>
            </div>
        </div>
    </div>
</div>
  )
}

export default Roadmap
