// Countdown.js
import React, { useState, useEffect } from 'react';
import './Countdown.css'; // Import CSS file for styling
import { useTranslation } from "react-i18next";
const Countdown = () => {
  const [t] = useTranslation();
  // Set the target date for the countdown (replace with your desired date)
  const targetDate = new Date('2024-02-10T23:59:59').getTime();

  // Initialize state variables for the countdown values
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    // Function to update countdown values every second
    const updateCountdown = () => {
      const now = new Date().getTime();
      const distance = targetDate - now;

      // Calculate days, hours, minutes, and seconds
      const daysRemaining = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hoursRemaining = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutesRemaining = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const secondsRemaining = Math.floor((distance % (1000 * 60)) / 1000);

      // Update state variables
      setDays(daysRemaining);
      setHours(hoursRemaining);
      setMinutes(minutesRemaining);
      setSeconds(secondsRemaining);
    };

    // Update countdown initially and every second
    updateCountdown();
    const interval = setInterval(updateCountdown, 1000);

    // Clean up interval on component unmount
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="countdown-container">
      <h2>Countdown to New Year</h2>
      <div className="countdown">
        <div className="countdown-item">
          <div className="countdown-value">{days}</div>
          <div className="countdown-label">{t('time1')}</div>
        </div>
        <div className="countdown-item">
          <div className="countdown-value">{hours}</div>
          <div className="countdown-label">{t('time2')}</div>
        </div>
        <div className="countdown-item">
          <div className="countdown-value">{minutes}</div>
          <div className="countdown-label">{t('time3')}</div>
        </div>
        <div className="countdown-item">
          <div className="countdown-value">{seconds}</div>
          <div className="countdown-label">{t('time4')}</div>
        </div>
      </div>
    </div>
  );
};

export default Countdown;
