import React from "react";

function TopBanner() {
  return (
    <div className="!hidden stack-top-banner">
      Buy and Stake for 354% Annual Rewards!
    </div>
  );
}

export default TopBanner;
