// Navbar.js
import React, { useState } from "react";
import "./Nav.css"; // Import CSS file for styling
import { useTranslation } from "react-i18next";
const Nav = () => {
  const [t, i18n] = useTranslation();
  const [showMenu, setShowMenu] = useState(false);

  // Function to handle smooth scrolling to sections
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      window.scrollTo({
        top: section.offsetTop,
        behavior: "smooth",
      });
      setShowMenu(false); // Close the menu after clicking a menu item on small screens
    }
  };

  return (
    <nav className="navbar">
      <div className="w-18 h-18">
        <img
          style={{ width: 100, height: 100 }}
          src="/assets/img/logo/logo.png"
        />
      </div>
      <div className={`menu-container ${showMenu ? "show" : ""}`}>
        <ul className="menu">
          <li onClick={() => scrollToSection("About")}>{t("about")}</li>
          <li onClick={() => scrollToSection("why")}>{t("why")}</li>
          <li onClick={() => scrollToSection("Roadmap")}>{t("roadmap")}</li>
          <li onClick={() => scrollToSection("faq")}>{t("faq")}</li>
          <li onClick={() => scrollToSection("contact")}>{t("con")}</li>
          <li></li>
          <li
            className="btn-box"
            style={{
              backgroundColor: "#00C4F4",
              width: "120px",
              padding: "15px",
              borderRadius: "10px",
            }}
          >
            {i18n.language == "ar" && (
              <option
                className="btn1"
                onClick={() => {
                  i18n.changeLanguage("en");
                }}
              >
                English
              </option>
            )}
            {i18n.language == "en" && (
              <option
                className="btn1"
                onClick={() => {
                  i18n.changeLanguage("ar");
                }}
              >
                Arabic
              </option>
            )}
          </li>
          {/* Add more menu items as needed */}
        </ul>
      </div>

      <div
        className="hamburger-menu text-dark"
        onClick={() => setShowMenu(!showMenu)}
      >
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>
    </nav>
  );
};

export default Nav;
