import React from "react";
import TopBanner from "../components/StackPage/TopBanner";
import Header from "../components/StackPage/Header";
import Footer from "../components/StackPage/Footer";
import MainStackComponent from "../components/StackPage/MainStackComponent";
function StackWTE() {
  return (
    <div className="w-full ">
      <div className="stack-site-container bg-image">
        <Header />
        <TopBanner />
        <MainStackComponent />
        <Footer />
      </div>
    </div>
  );
}

export default StackWTE;
